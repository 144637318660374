<template>
    <modal ref="modalAgregarEtapaEquipo" titulo="Añadir etapa" adicional="Añadir" @adicional="agregarEtapa">
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-9 my-2">
                    <p class="input-label-top">Etapa</p>
                    <ValidationProvider v-slot="{ errors }" name="etapa" rules="required">
                        <el-select v-model="etapaSelect" placeholder="Seleccionar etapa" size="small" class="w-100" @change="listConfiguraciones">
                            <el-option v-for="item in etapas" :key="item.value" :label="item.etapa" :value="item.id" />
                        </el-select>
                        <VeeError :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-9 my-2">
                    <p class="input-label-top mb-3">Seleccione las configuraciones de la etapa:</p>
                    <ValidationProvider v-slot="{ errors }" name="configuracion" rules="required">
                        <el-select v-model="idConfiguracion" placeholder="Seleccionar etapa" size="small" class="w-100" multiple>
                            <el-option v-for="item in configuraciones" :key="item.value" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <VeeError :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    data(){
        return {
            idConfiguracion: [],
            etapaSelect: '',
            configuraciones:[],
        }
    },

    computed:{
        ...mapGetters({
            etapas: 'obras/informacion/etapas',
            producto: 'obras/informacion/producto',
            equipos: 'obras/informacion/equipos',
        })
    },

    methods: {
        ...mapActions({
            Action_get_etapas: 'obras/informacion/Action_get_etapas',
            Action_post_add_etapas: 'obras/informacion/Action_post_add_etapas',
            Action_get_informacion_equipos: 'obras/informacion/Action_get_informacion_equipos',
        }),
        ...mapMutations({
            setProducto : 'obras/informacion/setProducto'
        }),

        async toggle(){
            this.clear()
            this.$refs.validator.reset()
            await this.Action_get_etapas(this.$route.params.id)
            this.$refs.modalAgregarEtapaEquipo.toggle()
        },
        async agregarEtapa(){
            let valid = await this.$refs.validator.validate()
            if (valid) {
                let payload = {
                    id_etapa: this.etapaSelect,
                    id_configuracion: this.idConfiguracion,
                    id_proyecto: this.$route.params.id,
                    id_producto:this.producto.id_producto,
                    tabla:this.producto.tabla
                }
                await this.Action_post_add_etapas(payload)
                await this.Action_get_informacion_equipos(this.$route.params.id)
                this.addEtapas()
                this.$refs.modalAgregarEtapaEquipo.toggle()
            }

        },
        listConfiguraciones(item){
            this.etapas.forEach(e => {
                if (e.id == item) {
                    this.configuraciones = e.configuraciones
                }
            });
        },
        addEtapas(){
            this.setProducto( this.equipos.find( f => f.id == this.producto.id ))
        },
        clear(){
            this.etapaSelect = ''
            this.idConfiguracion = []
        }

    }
}
</script>

<style lang="css" scoped>
</style>
